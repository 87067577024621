import React from "react";
import Faq from "./faq";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="intro-slider-container">
          <div
            className="owl-carousel owl-simple owl-light owl-nav-inside"
            data-toggle="owl"
            data-owl-options='{"nav": false}'
          >
            <div
              className="intro-slide"
              style={{
                backgroundImage: `url(${urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "home-img"
                )})`,
              }}
            >
              <div className="container intro-content mt-3">
                <h1 className="intro-title mt-3 mb-0">ΠΑΠΑΓΙΑΝΝΙΔΗΣ ΟΕ</h1>
                {/* End .h3 intro-subtitle */}
                <h3 className="intro-subtitle mb-3">
                  Η Σχολή Οδηγών ΠΑΠΑΓΙΑΝΝΙΔΗΣ ΟΕ ιδρύθηκε το 1996, στο Χαλάνδρι
                  από τον Απόστολο Παπαγιαννίδη ...
                </h3>
                {/* End .intro-title */}

                <a href="/profil" className="btn btn-primary home_slider">
                  <span>Δείτε περισσότερα</span>
                  <i className="icon-long-arrow-right"></i>
                </a>
              </div>
              {/* End .container intro-content */}
            </div>
            {/* End .intro-slide */}
          </div>
          {/* End .owl-carousel owl-simple */}

          <span className="slider-loader text-white"></span>
          {/* End .slider-loader */}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Faq></Faq>
            </div>
            <div className="col-md-6">
              <div className="widget">
                <h3 className="mt-3 mb-3">Γιατί Εμάς</h3>
                <ul className="widget-list">
                  <li>
                    <h4 className="mb-1">
                      <i
                        className="icon-check-circle-o mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "30px",
                        }}
                      ></i>
                      ΣΧΕΣΗ ΕΜΠΙΣΤΟΣΥΝΗΣ
                    </h4>
                    <p className="mb-2">
                      Χωρίς κρυφές χρεώσεις και ανύπαρκατα παράβολα. Η τιμή που
                      θα συμφωνήσουμε είναι και αυτή που θα πληρώσεις από τη
                      στιγμή που θα εγγραφείς μέχρι και τη στιγμή που θα
                      παραλάβεις το δίπλωμα οδήγησης από τη σχολή μας.
                    </p>
                  </li>
                  <li>
                    <h4 className="mb-1">
                      <i
                        className="icon-check-circle-o mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "30px",
                        }}
                      ></i>
                      ΕΥΕΛΙΚΤΟ ΩΡΑΡΙΟ
                    </h4>
                    <p className="mb-2">
                      Ρυθμίζουμε το πρόγραμμα μαθημάτων σύμφωνα με το ΔΙΚΟ ΣΟΥ
                      ΠΡΟΓΡΑΜΜΑ και τις δικές σου ανάγκες. Το μάθημα οδήγησης
                      μπορεί να ξεκινάει από τη σχολή και να τελειώνει σε μέρος
                      της επιλογής σου.
                    </p>
                  </li>
                  <li>
                    <h4 className="mb-1">
                      <i
                        className="icon-check-circle-o mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "30px",
                        }}
                      ></i>
                      ΕΓΓΓΥΗΣΗ ΧΑΜΗΛΟΤΕΡΗΣ ΤΙΜΗΣ
                    </h4>
                    <p className="mb-2">
                      Η χαμηλότερη τιμή της αγοράς με τις περισσότερες παροχές.
                    </p>
                  </li>
                </ul>
                {/* End .widget-list */}
              </div>
              {/* End .widget */}
            </div>
          </div>
          <hr className="mt-4 mb-5" />
          <h2 className="title text-center mb-3">BLOG</h2>
          <div className="row justify-content-center">
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Δεκ 3, 2015
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Η εκπαίδευση γίνεται με τα δικά μας σύγχρονα και ειδικά
                      διαμορφωμένα οχήματα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Η εκπαίδευση των υποψηφίων οδηγών – γίνεται με τα δικά μας
                      σύγχρονα και ειδικά διαμορφωμένα οχήματα όλων των
                      κατηγοριών: μοτοσικλέτες, αυτοκίνητα, λεωφορεία, φορτηγά
                      και νταλίκες.
                    </p>
                    <a
                      href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa">
                      Νοε 22, 2013
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa">
                      Να εκδοθεί ο Κ.Ο.Κ. στην Ελληνική Νοηματική Γλώσσα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Να αποδοθεί στην ελληνική νοηματική γλώσσα ο Κώδικας
                      Οδικής Κυκλοφορίας (Κ.Ο.Κ.) και να διανεμηθεί σε κωφούς
                      και βαρήκοους ζητά ο βουλευτής Λαρίσης της Νέας
                      Δημοκρατίας κ. Μάξιμος Χαρακόπουλος, με ερώτηση που
                      απευθύνει στον υπουργό Οικονομίας, Υποδομών, Ναυτιλίας και
                      Τουρισμού κ. Γιώργο Σταθάκη. Όπως αναφέρει...
                    </p>
                    <a
                      href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas">
                      Νοε 21, 2013
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas">
                      Οι εκπαιδευτές μας είναι αριστούχοι της σχολής μας
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Οι εκπαιδευτές μας είναι υπεύθυνοι δάσκαλοι, αριστούχοι
                      της σχολής μας με εμπειρία σε όλες τις εξεταστικές
                      υποψηφίων εκπαιδευτών.
                    </p>
                    <a
                      href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
          </div>
          {/*  End .row */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
