import React from "react";

const ExportDefault = (props) => {
  //const { component } = props;

  // const categories = component.getData("default.categories", []);

  return (
    <React.Fragment>
      <div className="mobile-menu-overlay"></div>
      <div className="mobile-menu-container mobile-menu-light">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>
          {/* <form
            onSubmit={(e) => component.checkSearchMobile(e)}
            method="get"
            className="mobile-search"
          >
            <label htmlFor="mobile-search" className="sr-only">
              Aναζήτηση
            </label>
            <input
              type="text"
              className="form-control"
              name="mobile-search"
              id="search_mobile"
              placeholder="Aναζήτηση προϊόντος ..."
            />
            <button
              className="btn btn-primary"
              id="btn-mobile-search"
              type="submit"
            >
              <i className="icon-search"></i>
            </button>
          </form> */}
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li>
                <a href="/" className="sf-with-ul nav_liks">
                  ΑΡΧΙΚΗ
                </a>

                <ul>
                  <li>
                    <a href="/online-test-drive" className="category-name">
                      Online test drive
                    </a>
                  </li>
                </ul>
              </li>

              <li className="active nav_liks">
                <a href="/profil">PROFIL</a>
              </li>

              <li>
                <a href="#" className="sf-with-ul nav_liks">
                  ΔΙΠΛΩΜΑΤΑ-ΥΠΗΡΕΣΙΕΣ
                </a>

                <ul>
                  <li>
                    <a
                      href="/diplomata-ipiresies/diplomata-mihanis"
                      className="category-name"
                    >
                      Δίπλωμα μηχανής
                    </a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/diplomata-autokinitou"
                      className="category-name"
                    >
                      Δίπλωμα αυτοκινήτου
                    </a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/epaggelmatika-diplomata-pei"
                      className="category-name"
                    >
                      Επαγγ. Διπλώματα/ ΠΕΙ
                    </a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/eidiki-adeia-odigisis-taxi"
                      className="category-name"
                    >
                      Ειδική άδεια οδήγησης ταξί
                    </a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/anatheoriseis"
                      className="category-name"
                    >
                      Αναθεωρήσεις
                    </a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/antikatastasi-metatropi"
                      className="category-name"
                    >
                      Αντικατάσταση &#8211; μετατροπή
                    </a>
                  </li>
                  <li>
                    <a href="/diplomata-ipiresies/metavivaseis">Μεταβιβάσεις</a>
                  </li>
                  <li>
                    <a
                      href="/diplomata-ipiresies/exoplismos-sxolon-odigon"
                      className="category-name"
                    >
                      Εξοπλ. Σχολών Οδηγών
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="nav_liks" href="/ipops-ekpaidevtes-sholon-odigon">
                  ΥΠΟΨ. ΕΚΠΑΙΔΕΥΤΕΣ
                </a>
              </li>
              <li>
                <a className="nav_liks" href="/blog">
                  BLOG
                </a>
              </li>
              <li>
                <a className="nav_liks" href="/epikoinonia">
                  ΕΠΙΚΟΙΝΩΝΙΑ
                </a>
              </li>
            </ul>
          </nav>
          {/* End .mobile-nav */}

          <div className="social-icons">
            <a
              href="https://www.facebook.com/casadipatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/CasadiPatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="https://gr.pinterest.com/casadipatsi/_created/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="https://www.instagram.com/casadipatsi/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div>
          {/* End .social-icons */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
