import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="mb-3">
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Η σχολή αναλαμβάνει τη μεταβίβαση αυτοκινήτων Ι.Χ. και Δ.Χ.
                </p>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Άδεια Κυκλοφορίας
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    ΚΤΕΟ
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Βεβαίωση Τελών Κυκλοφορίας
                  </li>
                </ul>
              </div>
              <p
                style={{
                  fontSize: "15px",
                }}
              >
                Για περαιτέρω πληροφορίες απευθυνθείτε σε κάποιο από τα
                καταστήματά μας.
              </p>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
