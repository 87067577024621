import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/results";
import Services from "../../Services/Services";

export default class Results extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("content").then(([contentService]) => {
      contentService
        .getCategories()
        .then((contentService) => {
          this.setData({
            "default.categories": contentService.getData("categories", []),
            "default.page": 1,
            "default.wishlist": this.getWishlist(),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    Services.get("content").then(([contentService]) => {
      contentService
        .search(this.getPage().getParam("word", ""))
        .then((contentService) => {
          this.setData({
            "default.category_items": contentService.getData("search", []),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    this.addScripts({
      "jquery.min": "/assets/js/jquery.min",
      "bootstrap.bundle.min": "/assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "/assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "/assets/js/jquery.waypoints.min",
      "superfish.min": "/assets/js/superfish.min",
      "owl.carousel.min": "/assets/js/owl.carousel.min",
      "jquery.plugin.min": "/assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "/assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "/assets/js/jquery.countdown.min",
      main: "/assets/js/main",
      "demo-2": "/assets/js/demos/demo-2",
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  getCurrentWord() {
    return this.getPage().getParam("word", "");
  }

  getWishlist() {
    const wishlist = this.getHelpers("state").get("wishlist");
    if (wishlist) {
      var array = wishlist.split(",");
      return array;
    } else {
      return [];
    }
  }

  addToWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    // stateHelper.remove("wishlist", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });
    var array = [];
    var wishlist = stateHelper.get("wishlist", null);
    if (wishlist) {
      array = wishlist.split(",");
      if (!array.includes(slug)) {
        array.push(slug);
      }
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    }

    this.setData({
      "default.wishlist": array,
    });
  }

  removeFromWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");

    var array = stateHelper.get("wishlist").split(",");
    var index = array.findIndex((el) => el === slug);
    array.splice(index, 1);
    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    this.setData({
      "default.wishlist": array,
    });
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
