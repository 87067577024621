import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/replacement-conversation";

export default class ReplacementConversation extends Page {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setWindowTitle(
      "ΠΑΠΑΓΙΑΝΝΙΔΗΣ Ο. Ε. - ΣΧΟΛΕΣ ΟΔΗΓΩΝ | Δίπλωμα μηχανής"
    );

    this.setData({
      "default.page": 2,
    });

    this.addScripts({
      "jquery.min": "/assets/js/jquery.min",
      "bootstrap.bundle.min": "/assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "/assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "/assets/js/jquery.waypoints.min",
      "superfish.min": "/assets/js/superfish.min",
      "owl.carousel.min": "/assets/js/owl.carousel.min",
      "jquery.plugin.min": "/assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "/assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "/assets/js/jquery.countdown.min",
      main: "/assets/js/main",
      "demo-2": "/assets/js/demos/demo-2",
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.setSeoData();
  }

  async setSeoData() {
    const account = this.getHelpers("env").getDefaultAccount();

    const translations = await this.getHelpers("translate").scope({
      account: account,
      app: "content",
      entity: "item",
      entity_slug: "seo",
      scope: "default",
    });

    const description = translations.find(
      (translation) => translation.slug === "default-description"
    ) || { translation: "" };

    const keywords = translations.find(
      (translation) => translation.slug === "default-keywords"
    ) || { translation: "" };

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description.translation);

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords.translation);
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
