import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="widget widget-cats mb-3">
                <h3 className="widget-title">
                  <b>ΚΑΤΗΓΟΡΙΕΣ</b>
                </h3>
                <ul>
                  <li style={{ maxWidth: "350px" }}>
                    <a href="#">
                      ΦΟΡΤΗΓΟ (Γ ΚΑΤΗΓΟΡΙΑ)<span>18 ετών και άνω</span>
                    </a>
                  </li>
                  <li style={{ maxWidth: "350px" }}>
                    <a href="#">
                      ΛΕΩΦΟΡΕΙΟ (Δ ΚΑΤΗΓΟΡΙΑ)<span>21 ετων και άνω</span>
                    </a>
                  </li>
                  <li style={{ maxWidth: "350px" }}>
                    <a href="#">
                      ΝΤΑΛΙΚΑ (Ε ΚΑΤΗΓΟΡΙΑ)<span>20 ετων και άνω</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                    παραμονής
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία διπλώματος (εάν υπάρχει κάποια άλλη κατηγορία)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    4 φωτογραφίες τύπου διαβατηρίου (εκ των οποίων οι 2 είναι
                    για τους γιατρούς )
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    ΑΦΜ
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΑΠΑΙΤΟΥΜΕΝΕΣ ΔΗΛΩΣΕΙΣ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "229b4fb10ed645b6112d74bfac99f34rwef"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      ΑΙΤΗΣΗ ΕΚΤΥΠΩΣΗΣ.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Για Γ ή Δ κατηγορία:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "53bdc688212f14583216c029a977435f"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 03.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Για Ε κατηγορία:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "53bdc688212f14583216c029a9774erfe35f"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 05.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αν δεν είστε υποχρεωμένος για ΠΕΙ:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "229b4fb10ed64rgvf5b6112d74bfac99fe27"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 04.pdf
                    </a>{" "}
                    (μαζί με τις υπόλοιπες)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Όσοι δικαιούνται ΠΕΙ με σεμινάριο:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "2704287deefa9a2028b91855542dd2c8"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 18.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Χορήγηση η ανανέωση κάρτας ταχογράφου:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "065ff541d08d928f2c3e0e8946578b5e"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Υπεύθυνη Ν105.doc
                    </a>{" "}
                    (Εξουσιοδότηση στον Παπαγιαννίδη Γεώργιο)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Για κατηγορία Β96 (ρυμουλκόμενο έως 1250kg):{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "229b4fb10ed645b6112d7erf4bffdvac99fe27"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 07.pdf
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 mb-3">
              <h3 className="widget-title">
                <b>Π.Ε.Ι. (ΠΙΣΤΟΠΟΙΗΣΗ ΕΠΑΓΓΕΛΜΑΤΙΚΗΣ ΙΚΑΝΟΤΗΤΑΣ)</b>
              </h3>
              <ol>
                <li className="mb-1">
                  1. Όσοι έχουν δίπλωμα φορτηγού πριν τις 10/9/2009 ή δίπλωμα
                  λεωφορείου πριν τις 10/9/2008, μπορούν να αποκτήσουν το Π.Ε.Ι
                  με παρακολούθηση σεμιναρίων, χωρίς να δώσουν εξετάσεις.
                  <h4 className="widget-title mt-2">
                    <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                  </h4>
                  <ul>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                      παραμονής
                    </li>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      Φωτοτυπία διπλώματος
                    </li>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      2 φωτογραφίες τύπου διαβατηρίου
                    </li>
                  </ul>
                </li>
                <li>
                  2. Όσον αφορά τις μετέπειτα ημερομηνίες, το Π.Ε.Ι. αποκτάται
                  με θεωρητική και πρακτική εξέταση.
                  <h4 className="widget-title mt-2">
                    <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                  </h4>
                  <ul>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                      παραμονής
                    </li>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      Φωτοτυπία διπλώματος
                    </li>
                    <li>
                      <i
                        className="icon-angle-right mr-2"
                        style={{
                          color: "#f32039",
                          fontSize: "10px",
                        }}
                      ></i>
                      2 φωτογραφίες τύπου διαβατηρίου
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
