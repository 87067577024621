import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="mb-3 mt-3">
            <h3 className="widget-title">
              <b>Νέα σειρά χαρτών ειδικά για τη σχολή οδηγών σας</b>
            </h3>
            <ol>
              <li className="mb-1">
                Διαθέτουμε το πλήρες σετ χαρτών στα ελληνικά για τις σχολές
                εκπαίδευσης υποψηφίων οδηγών (βάσει των νέων προδιαγραφών του
                Υπουργείου Μεταφορών).
              </li>
              <li className="mb-1">
                Οι χάρτες είναι πολύχρωμοι και πλαστικοποιημένοι με πολύ μεγάλη
                διάρκεια ζωής.
              </li>
              <li className="mb-1">
                Οι χάρτες μας βγαίνουν σε σετ των 12 τεμαχίων ή ανάλογα με τις
                ανάγκες της σχολής σας.
              </li>
            </ol>
          </div>

          <div
            style={{
              border: "1px solid #f32039",
              borderRadius: "10px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#f32039",
              margin: "0px 20%",
            }}
          >
            <i className="icon-info-circle"></i> ΤΩΡΑ ΚΑΙ ΑΓΟΡΑ-ΤΟΠΟΘΕΤΗΣΗ ΠΕΤΑΛ
            ΓΙΑ ΟΛΑ ΤΑ ΟΧΗΜΑΤΑ ΣΤΙΣ ΚΑΛΥΤΕΡΕΣ ΤΙΜΕΣ
          </div>
          <div className="row mt-3 mb-3">
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-1"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-2"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-3"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-4"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-5"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-6"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-7"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-8"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-9"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-10"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-11"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-sm-6 col-lg-4 mb-2">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "img-12"
                )}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
