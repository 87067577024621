import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mb-3 mb-lg-0">
                {/* <h2 className="title">
                  Τι είναι το <span className="text-primary">Stockhouse</span>;
                </h2> */}
                <p className="lead mb-1">
                  <strong>Η Σχολή Οδηγών ΠΑΠΑΓΙΑΝΝΙΔΗΣ ΟΕ</strong> ιδρύθηκε το
                  1996, στο Χαλάνδρι από τον Απόστολο Παπαγιαννίδη , ο οποίος
                  διατηρούσε σχολή οδηγών με τον αδελφό του Σωκράτη από το 1964,
                  αρχικά στην οδό Μπουμπουλίνας στην Αθήνα και μετέπειτα στην
                  πλατεία Χίου στο Χαλάνδρι. Το 2000 τα ηνία της σχολής ανέλαβαν
                  οι γιοι του, Γιώργος και Κώστας, οι οποίοι προχώρησαν στην
                  ίδρυση τριών ακόμη υποκαταστημάτων, στο Χολαργό, στη Δουκίσσης
                  Πλακεντίας και στους Αμπελοκήπους.
                </p>
                <p className="lead mb-1">
                  <strong>Οι υπηρεσίες</strong> που παρέχονται από τη Σχολή
                  Οδηγών ΠΑΠΑΓΙΑΝΝΙΔΗΣ ΟΕ αφορούν στην απόκτηση διπλώματος
                  οδήγησης όλων των κατηγοριών, καθώς και στη θεωρητική και
                  πρακτική κατάρτιση των υποψήφιων οδηγών και υποψήφιων
                  εκπαιδευτών οδήγησης. Επιπλέον, εξυπηρετούμε τους πελάτες μας
                  με αναθεωρήσεις, αντικαταστάσεις διπλωμάτων, μεταβιβάσεις
                  οχημάτων και άλλες εργασίες διεκπεραίωσης. Επίσης, στη σχολή
                  λειτουργούν τμήματα στα οποία διεξάγονται σεμινάρια για την
                  απόκτηση του Πιστοποιητικού Επαγγελματικής Ικανότητας (ΠΕΙ),
                  απαραίτητο για όσους οδηγούν επαγγελματικό όχημα.
                </p>
              </div>
              {/* End .col-lg-5 */}
              <div className="col-lg-6 offset-lg-1">
                <p className="lead mb-1">
                  <strong>Η εκπαίδευση</strong> των υποψηφίων οδηγών γίνεται με
                  ιδιόκτητα σύγχρονα οχήματα όλων των κατηγοριών (μοτοσυκλέτες,
                  αυτοκίνητα, φορτηγό, λεωφορείο, νταλίκα). Καθημερινά γίνεται
                  προσομοίωση της εξέτασης από τους Γιώργο και Κώστα
                  Παπαγιαννίδη, οι οποίοι είναι προσωπικά υπεύθυνοι για την
                  εξέλιξη κάθε υποψηφίου, αλλά και από εκπαιδευτές, αριστούχους
                  της σχολής, με πολύχρονη εμπειρία, μεθοδικότητα, υπευθυνότητα
                  και ευέλικτο ωράριο. Οι εκπαιδευτές αναλαμβάνουν και
                  μετεκπαίδευση ατόμων τα οποία έχουν ήδη δίπλωμα αλλά επιθυμούν
                  πρακτική εξάσκηση στη σχολή μας.
                </p>
                <p className="lead mb-1">
                  Τέλος, ένα πολύ σημαντικό κομμάτι της αυστηρότητας στην{" "}
                  <strong>Σχολή Οδηγών ΠΑΠΑΓΙΑΝΝΙΔΗΣ ΟΕ </strong>είναι η
                  εκπαίδευση και η παρουσία στην εξέταση των
                  <strong> υποψήφιων εκπαιδευτών οδήγησης</strong>, με τα
                  καλύτερα αποτελέσματα και ποσοστά επιτυχίας στις τελικές
                  εξετάσεις.
                </p>
                <div className="">
                  <img
                    //style={{ height: "330px" }}
                    src="/images/logo.png"
                    alt=""
                    className="about-img-front"
                  />
                </div>
              </div>
              {/* End .col-lg-6 */}
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
