import React from "react";

const ExportDefault = (props) => {
  return (
    <React.Fragment>
      <h3 className="mt-3 mb-3">Συχνές Ερωτήσεις</h3>
      {/* End .title */}
      <div className="accordion accordion-rounded" id="accordion-1">
        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-1">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-1"
                aria-expanded="false"
                aria-controls="collapse-1"
              >
                Χρειάζεται να παρακολουθήσω τη θεωρία;
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-1"
            className="collapse"
            aria-labelledby="heading-1"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              Τα θεωρητικά μαθήματα είναι απαραίτητα για τη σωστή εμπέδωση της
              κυκλοφορίας και του κώδικα. Ακόμη θα σας διευκολύνουν κατά την
              πρακτική εκπαίδευση αφού ο εκπαιδευτής θα εξοικονομεί χρόνο και θα
              επικεντρώνεται στην τεχνική οδήγησης. Έχει παρατηρηθεί ότι οι
              μαθητές μετά την παρακολούθηση των θεωρητικών μαθημάτων γίνονται
              καλύτεροι οδηγοί και ποιο προσεκτικοί.
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}

        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-2">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-2"
                aria-expanded="false"
                aria-controls="collapse-2"
              >
                Δεν διαβάζω καλά ελληνικά…
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-2"
            className="collapse"
            aria-labelledby="heading-2"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              Το υπουργείο μεταφορών σας δίνει την δυνατότητα να δώσετε εξέταση
              στα ΕΛΛΗΝΙΚΑ, ΑΛΒΑΝΙΚΑ, ΡΩΣΙΚΑ, ΑΓΓΛΙΚΑ. Αρκεί να το δηλώσετε.
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}

        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-3">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-3"
                aria-expanded="false"
                aria-controls="collapse-3"
              >
                Μπορώ να δανειστώ το πρόγραμμα στο σπίτι;
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-3"
            className="collapse"
            aria-labelledby="heading-3"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              Φυσικά και μπορείτε. Ζητήστε το πρόγραμμα από το γραφείο της σχολή
              και πληροφορίες για την εγκατάσταση.
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}

        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-4">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-4"
                aria-expanded="false"
                aria-controls="collapse-4"
              >
                Γίνεται μάθημα και με computer Η/Υ;
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-4"
            className="collapse"
            aria-labelledby="heading-4"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              Παράλληλα με τα θεωρητικά σας μαθήματα η σχολή σας παρέχει ΔΩΡΕΑΝ
              την άσκηση σε ηλεκτρονικό υπολογιστή. Ο ηλεκτρονικός υπολογιστής
              θα σας βοηθήσει να ελέγξετε τις γνώσεις που αποκτήσατε στη θεωρία.
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}

        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-5">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-5"
                aria-expanded="false"
                aria-controls="collapse-5"
              >
                Έχει λήξει το δίπλωμά μου, τι χρειάζεται να κάνω;
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-5"
            className="collapse"
            aria-labelledby="heading-5"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              Δείτε τι χρειάζεται κάνοντας κλικ <a href="/">εδώ</a>
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}

        <div className="card card-box card-sm bg-light">
          <div className="card-header" id="heading-6">
            <h2 className="card-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapse-6"
                aria-expanded="false"
                aria-controls="collapse-6"
              >
                Δικαιολογητικά σε περίπτωση απώλειας διπλώματος;
              </a>
            </h2>
          </div>
          {/* End .card-header */}
          <div
            id="collapse-6"
            className="collapse"
            aria-labelledby="heading-6"
            data-parent="#accordion-1"
          >
            <div className="card-body">
              2 φωτογραφίες διαβατηρίου
              <br />
              Ταυτότητα
              <br />
              ΑΦΜ (εκκαθαριστικό)
              <br />
            </div>
            {/* End .card-body */}
          </div>
          {/* End .collapse */}
        </div>
        {/* End .card */}
      </div>
      {/* End .accordion */}
    </React.Fragment>
  );
};

export default ExportDefault;
