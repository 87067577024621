import React from "react";
const ExportDefault = (props) => {
  const { component } = props;
  // const categories = component.getData("default.categories", []);
  const current_page = component.getData("default.page", null);

  return (
    <header className="header header-2 header-intro-clearance">
      <div className="header-middle">
        <div className="container">
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <a href="/" className="logo">
              <img src="/images/logo.png" alt="Logo" width="200" />
            </a>
          </div>
          {/* End .header-left */}

          <div className="header-center">
            {/* <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
              <a href="/" className="search-toggle" role="button">
                <i className="icon-search"></i>
              </a>
              <form onSubmit={(e) => component.checkSearch(e)} method="get">
                <div className="header-search-wrapper search-wrapper-wide">
                  <label htmlFor="q" className="sr-only">
                    Aναζήτηση
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="q"
                    id="search"
                    placeholder="Aναζήτηση προϊόντος ..."
                  />
                  <button className="btn btn-primary" type="submit">
                    <i className="icon-search"></i>
                  </button>
                </div>
              </form> 
            </div> */}
          </div>
          {/* End .header-search */}
          <div id="header_contact_info" className="header-right">
            <div className="contact_info" id="info">
              <ul className="contact-list mt-1">
                <li className="mb-0">
                  <i className="icon-phone"></i>
                  <a href="tel:+302106835450">+30 2106835450</a>
                </li>
                <li className="mb-0">
                  <i className="icon-map-marker"></i>
                  <a
                    style={{ marginRight: "10px" }}
                    href="/epikoinonia"
                    id="shops"
                  >
                    ΚΑΤΑΣΤΗΜΑΤΑ
                  </a>
                </li>
              </ul>
            </div>
            {/* End .compare-dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <div className="mt-1" id="mobile_contact_info">
        <div className="text-center">
          <ul>
            <li
              className="li_xs_fix"
              style={{
                margin: "0px 3px",
              }}
            >
              <a href="tel:+302106835450" style={{ color: "#696969" }}>
                <i
                  className="icon-phone"
                  style={{ color: "red", fontSize: "20px" }}
                ></i>
                +30 2106835450
              </a>
            </li>
            <li
              className="li_xs_fix"
              style={{
                margin: "0px 3px",
              }}
            >
              <a style={{ marginLeft: "5px" }} href="/epikoinonia" id="shops">
                <i
                  className="icon-map-marker"
                  style={{ color: "red", fontSize: "20px" }}
                ></i>
                ΚΑΤΑΣΤΗΜΑΤΑ
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="header-bottom sticky-header">
        <div id="my_cont" className="container">
          <div className="header-left"></div>

          <div className="header-center">
            <nav className="main-nav">
              <ul className="menu sf-arrows">
                <li
                  className={
                    current_page === 0 ? "active nav_liks" : "nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/" className="sf-with-ul">
                    ΑΡΧΙΚΗ
                  </a>

                  <ul>
                    <li>
                      <a href="/online-test-drive" className="category-name">
                        Online Test Drive
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li
                  className={
                    current_page === 0
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/">ΑΡΧΙΚΗ</a>
                </li> */}
                <li
                  className={
                    current_page === 1
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/profil">PROFIL</a>
                </li>

                <li
                  className={
                    current_page === 2 ? "active nav_liks" : "nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="#" className="sf-with-ul">
                    ΔΙΠΛΩΜΑΤΑ ΥΠΗΡΕΣΙΕΣ
                  </a>

                  <ul>
                    <li>
                      <a
                        href="/diplomata-ipiresies/diplomata-mihanis"
                        className="category-name"
                      >
                        Δίπλωμα μηχανής
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/diplomata-autokinitou"
                        className="category-name"
                      >
                        Δίπλωμα αυτοκινήτου
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/epaggelmatika-diplomata-pei"
                        className="category-name"
                      >
                        Επαγγ. Διπλώματα/ ΠΕΙ
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/eidiki-adeia-odigisis-taxi"
                        className="category-name"
                      >
                        Ειδική άδεια οδήγησης ταξί
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/anatheoriseis"
                        className="category-name"
                      >
                        Αναθεωρήσεις
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/antikatastasi-metatropi"
                        className="category-name"
                      >
                        Αντικατάσταση &#8211; μετατροπή
                      </a>
                    </li>
                    <li>
                      <a href="/diplomata-ipiresies/metavivaseis">
                        Μεταβιβάσεις
                      </a>
                    </li>
                    <li>
                      <a
                        href="/diplomata-ipiresies/exoplismos-sxolon-odigon"
                        className="category-name"
                      >
                        Εξοπλ. Σχολών Οδηγών
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    current_page === 3
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/ipops-ekpaidevtes-sholon-odigon">
                    ΥΠΟΨ. ΕΚΠΑΙΔΕΥΤΕΣ
                  </a>
                </li>
                <li
                  className={
                    current_page === 4
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/blog">BLOG</a>
                </li>
                <li
                  className={
                    current_page === 5
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                  style={{ height: "75px" }}
                >
                  <a href="/epikoinonia">ΕΠΙΚΟΙΝΩΝΙΑ</a>
                </li>
              </ul>
              {/* End .menu */}
            </nav>
            {/* End .main-nav */}
          </div>
          {/* End .header-center */}

          <div className="header-right"></div>
        </div>

        {/* End .container */}
      </div>
      {/* End .header-bottom */}
    </header>
  );
};

export default ExportDefault;
