import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <footer className="footer footer-2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <a href="/" className="logo">
              <img src="/images/logo-light.png" alt="Logo" width="200" />
            </a>
            <p></p>
          </div>
          {/* End .col-sm-12 col-lg-3 */}
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

      <div className="footer-bottom">
        <div className="container">
          <p className="footer-copyright">
            Copyright &copy; 2022 Papagiannidis O.E. | All Rights Reserved
          </p>

          {/* <div className="social-icons">
            <span className="social-label">Social Media</span>

            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div> */}
          {/* End .soial-icons */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-bottom */}
    </footer>
  );
};

export default ExportDefault;
