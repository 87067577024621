import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="mb-3 mt-3">
            <div
              style={{
                border: "1px solid #f32039",
                borderRadius: "10px",
                textAlign: "center",
                color: "white",
                backgroundColor: "#f32039",
                fontSize: "20px",
              }}
            >
              <i className="icon-info-circle"></i> Η σχολή μας διαθέτει την
              ικανότητα να εκπαιδεύσει και να δώσει την άδεια άσκησης
              εκπαιδευτών.
            </div>
            <ol>
              <li className="mt-3 mb-1">
                Γίνεται καθημερινή προσομοίωση της εξέτασης από τους Γιώργο και
                Κώστα Παπαγιαννίδη, οι οποίοι με πλήρη ενημέρωση, ηρεμία και
                προπάντων εμπειρία, είναι υπεύθυνοι για την εκπαίδευση και
                εξέταση των υποψήφιων δασκάλων.
              </li>
              <li className="mb-1">
                Για αρχική χορήγηση ή ανανέωση άδειας εκπαιδευτή κατεβάστε και
                συμπληρώστε την αίτηση{" "}
                <a
                  href={urlHelper.createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    "gallery",
                    "default",
                    "2704287derfefa9a2028b91855542dd2c8e"
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  M-TAO 25.pdf
                </a>{" "}
                .
              </li>
              <li className="mb-1">
                Για περισσότερες λεπτομέρειες ή ανακοινώσεις, μπορείτε να
                ενημερωθείτε από το <a href="/blog">blog</a> μας ή να
                επικοινωνήσετε μαζί μας στα τηλέφωνα επικοινωνίας.
              </li>
              <li className="mb-1">
                <a
                  href={urlHelper.createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    "gallery",
                    "default",
                    "questions-pdf"
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  ΘΕΜΑΤΑ ΕΞΕΤΑΣΕΩΝ ΠΙΣΤΟΠΟΙΗΣΗΣ ΑΡΧΙΚΗΣ ΕΠΑΓΓΕΛΜΑΤΙΚΗΣ
                  ΚΑΤΑΡΤΙΣΗΣ ΕΙΔΙΚΟΤΗΤΑΣ Ι.Ε.Κ.pdf
                </a>
              </li>
            </ol>
          </div>
          <iframe
            style={{ maxWidth: "560px" }}
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/9RfFiHfoVc8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <br></br>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
