import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/not-found";

const redirects = {
  "/index.php/sofa.html": "category/sofas",
  "/index.php/mikroepipla.html": "category/others",
  "/index.php/table.html": "category/tables",
  "/index.php/coffee-table.html": "category/living-room-tables",
  "/index.php/bed.html": "category/beds",
  "/index.php/polythrones.html": "category/armchairs",
  "/index.php/faq": "/about-us",
};

export default class NotFound extends Page {
  title = "not-found";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    const path = this.props.location.pathname;

    if (redirects[path]) {
      this.redirect("/" + redirects[path]);
    }
  }
}
