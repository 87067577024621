import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container mt-3">
          <div id="custom_map" className="map"></div>
          {/* End #map */}
          <hr className="mt-4 mb-5" />
          <div className="stores mb-4 mb-lg-5">
            <h2 className="title text-center mb-3">
              ΣΧΟΛΕΣ ΟΔΗΓΩΝ ΠΑΠΑΓΙΑΝΝΙΔΗ Ο.Ε.
            </h2>

            <div className="row">
              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "office-plakentias"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">Κεντρικό: Δ. ΠΛΑΚΕΝΤΙΑΣ</h3>
                        <address>
                          Ηρακλείτου &amp; Ελλησπόντου 11-13 (Μετρό Δουκίσσης
                          Πλακεντίας)
                        </address>
                        <div>
                          <a href="tel:+302106835450">+30 2106835450</a>
                          {/* <p>email: @ </p> */}
                        </div>
                        <h4 className="store-subtitle">Ωράριο Λειτουργίας:</h4>
                        <div>Δευτέρα-Παρασκευή 9:30 π.μ. – 20:00 μ.μ.</div>
                        <a
                          href="https://www.google.com/maps/place/%CE%A0%CE%91%CE%A0%CE%91%CE%93%CE%99%CE%91%CE%9D%CE%9D%CE%99%CE%94%CE%97%CE%A3+%CE%9F%CE%95+-+%CE%A3%CF%87%CE%BF%CE%BB%CE%AE+%CE%9F%CE%B4%CE%B7%CE%B3%CF%8E%CE%BD+-+%CE%A7%CE%B1%CE%BB%CE%AC%CE%BD%CE%B4%CF%81%CE%B9/@38.0250351,23.836571,21z/data=!4m5!3m4!1s0x14a198f8f7fe29d1:0xd3db640ea42d4354!8m2!3d38.02507!4d23.8366273"
                          rel="noreferrer"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "office-halandri"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Υποκατάστημα Α’: ΧΑΛΑΝΔΡΙ
                        </h3>
                        <address>Αριστοτέλους 50, Χαλάνδρι</address>
                        <div>
                          <a href="tel:+302106820020">+30 2106820020</a>
                          {/* <p>email: @</p> */}
                        </div>
                        <h4 className="store-subtitle">Ωράριο Λειτουργίας:</h4>
                        <div>Δευτέρα-Παρασκευή 10:00 π.μ. – 20:00 μ.μ.</div>
                        <div>Σάββατο 10:00 π.μ. – 14:00 μ.μ.</div>
                        <a
                          href="https://www.google.com/maps/place/%CE%A0%CE%91%CE%A0%CE%91%CE%93%CE%99%CE%91%CE%9D%CE%9D%CE%99%CE%94%CE%97%CE%A3+%CE%9F.%CE%95.+%CE%A3%CE%A7%CE%9F%CE%9B%CE%95%CE%A3+%CE%9F%CE%94%CE%97%CE%93%CE%A9%CE%9D/@38.0198139,23.8019006,21z/data=!4m5!3m4!1s0x14a198f857e8001b:0x8dca8328b253d37!8m2!3d38.0198571!4d23.8019152"
                          className="btn btn-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "office-holargou"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Υποκατάστημα Β’: ΧΟΛΑΡΓΟΣ
                        </h3>
                        <address>Αναστάσεως 9, Χολαργός</address>
                        <div>
                          <a href="tel:+302106540590">+30 2106540590</a>
                          {/* <p>email: @</p> */}
                        </div>
                        <h4 className="store-subtitle">Ωράριο Λειτουργίας:</h4>
                        <div>Δευτέρα-Παρασκευή 9:00 π.μ. – 19:00 μ.μ.</div>
                        <a
                          href="https://www.google.com/maps/place/%CE%A0%CE%91%CE%A0%CE%91%CE%93%CE%99%CE%91%CE%9D%CE%9D%CE%99%CE%94%CE%97%CE%A3+%CE%9F.%CE%95.+%CE%A3%CE%A7%CE%9F%CE%9B%CE%95%CE%A3+%CE%9F%CE%94%CE%97%CE%93%CE%A9%CE%9D/@37.9999465,23.7873979,21z/data=!4m5!3m4!1s0x14a1983fe132ba7f:0xd2e5f90918258628!8m2!3d37.9999503!4d23.7875393"
                          className="btn btn-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "office-ampelokoipoi"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Υποκατάστημα Γ’: ΑΜΠΕΛΟΚΗΠΟΙ
                        </h3>
                        <address>
                          Λ. Ριανκούρ 34 &amp; Αργολίδος 42, Αμπελόκηποι, Αθήνα
                        </address>
                        <div>
                          <a href="tel:+302106997776">+30 2106997776</a>
                          {/* <p>email: @</p> */}
                        </div>
                        <h4 className="store-subtitle">Ωράριο Λειτουργίας:</h4>
                        <div>Δευτέρα-Παρασκευή 9:00 π.μ. – 14:00 μ.μ.</div>
                        <div>
                          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;17:00
                          μ.μ. – 20:00 μ.μ.
                        </div>

                        <a
                          href="https://www.google.com/maps/place/%CE%A0%CE%91%CE%A0%CE%91%CE%93%CE%99%CE%91%CE%9D%CE%9D%CE%99%CE%94%CE%97%CE%A3+%CE%9F.%CE%95.+%CE%A3%CE%A7%CE%9F%CE%9B%CE%95%CE%A3+%CE%9F%CE%94%CE%97%CE%93%CE%A9%CE%9D/@37.9905982,23.760882,21z/data=!4m5!3m4!1s0x14a1a2aa37656c71:0x5aab1dbd3fb6e423!8m2!3d37.9905925!4d23.7610261"
                          className="btn btn-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "office-athens"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">Υποκατάστημα Δ’: ΑΘΗΝΑ</h3>
                        <address>Μεσογείων 31 &amp; Βοιωτίας 2, Αθήνα</address>
                        <div>
                          <a href="tel:+302130422019">+30 2130422019</a>
                          {/* <p>email: @</p> */}
                        </div>
                        <h4 className="store-subtitle">Ωράριο Λειτουργίας:</h4>
                        <div>Δευτέρα-Παρασκευή 9:00 π.μ. – 14:00 μ.μ.</div>
                        <div>
                          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;17:00
                          μ.μ. – 20:00 μ.μ.
                        </div>
                        <a
                          href="https://www.google.com/maps/place/%CE%A0%CE%91%CE%A0%CE%91%CE%93%CE%99%CE%91%CE%9D%CE%9D%CE%99%CE%94%CE%97%CE%A3+%CE%9F.%CE%95.+%CE%A3%CE%A7%CE%9F%CE%9B%CE%95%CE%A3+%CE%9F%CE%94%CE%97%CE%93%CE%A9%CE%9D/@37.9866845,23.7644725,21z/data=!4m5!3m4!1s0x14a198001747fd09:0x1c0065f3d38f6f1e!8m2!3d37.9866845!4d23.7646093"
                          className="btn btn-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title mb-1">Έχετε οποιαδήποτε ερωτήση;</h2>
              {/* End .title mb-2 */}
              <p className="mb-2">
                Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή
              </p>

              <form
                onSubmit={(e) => {
                  component.sendForm(e);
                }}
                className="contact-form mb-3"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cname" className="sr-only">
                      Όνομα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cname"
                      placeholder="Όνομα *"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το όνομά σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="cemail" className="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το email σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cphone" className="sr-only">
                      Τηλέφωνο
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Συμπληρώστε το τηλεφωνό σας!"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="cphone"
                      placeholder="Τηλέφωνο *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="csubject" className="sr-only">
                      Θεμα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="csubject"
                      placeholder="Θεμα"
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <label htmlFor="cmessage" className="sr-only">
                  Message
                </label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="4"
                  id="cmessage"
                  required
                  placeholder="Τι έχετε στο μυαλό σας;"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Συμπληρώστε το μήνυμά σας!")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                ></textarea>

                <button
                  type="submit"
                  className="btn btn-outline-primary-2 btn-minwidth-sm"
                >
                  <span>ΥΠΟΒΟΛΗ</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </form>
              {/* End .contact-form */}
            </div>
            {/* End .col-lg-6 */}
            <div className="col-lg-6 mb-2 mb-lg-0">
              <h2 className="title mb-1">Στοιχεία Eπικοινωνίας</h2>
              {/* End .title mb-2 */}
              <div className="row">
                <div className="col-sm-7">
                  <div className="contact-info">
                    <ul className="contact-list mt-1">
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+306932449090">+306932449090</a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+306977319318">+306977319318</a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+306985756500">+306985756500</a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+306984476554">+306984476554</a>
                      </li>
                      <li>
                        <i className="icon-envelope"></i>
                        <a href="mailto:papagiannidisoe@hotmail.com">
                          papagiannidisoe@hotmail.com
                        </a>
                      </li>
                    </ul>
                    {/* End .contact-list */}
                  </div>
                  {/* End .contact-info */}
                </div>
                {/* End .col-sm-7 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .col-lg-6 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
