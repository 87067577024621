import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();
  // const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-sm-8">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">Νοε 22, 2013</div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Να εκδοθεί ο Κ.Ο.Κ. στην Ελληνική Νοηματική Γλώσσα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <br></br>
                    <ul className="text-left">
                      <li>
                        Να αποδοθεί στην ελληνική νοηματική γλώσσα ο Κώδικας
                        Οδικής Κυκλοφορίας (Κ.Ο.Κ.) και να διανεμηθεί σε κωφούς
                        και βαρήκοους ζητά ο βουλευτής Λαρίσης της Νέας
                        Δημοκρατίας κ. Μάξιμος Χαρακόπουλος, με ερώτηση που
                        απευθύνει στον υπουργό Οικονομίας, Υποδομών, Ναυτιλίας
                        και Τουρισμού κ. Γιώργο Σταθάκη.
                      </li>
                      <br></br>
                      <li>
                        Όπως αναφέρει ο Θεσσαλός πολιτικός στην ερώτησή του
                        «σύμφωνα με την Ομοσπονδία Κωφών Ελλάδος (ΟΜ.Κ.Ε.) είναι
                        ιδιαίτερα σημαντικό να αποδοθεί στην Ελληνική Νοηματική
                        Γλώσσα ο Κώδικας Οδικής Κυκλοφορίας». Ο σκοπός, βεβαίως,
                        δεν είναι άλλος από το να καταστεί «καλύτερη η κατανόηση
                        του Κ.Ο.Κ. από τα κωφά-βαρήκοα άτομα, λόγω του ότι η
                        νοηματική γλώσσα είναι η μητρική τους».
                      </li>
                      <br></br>
                      <li>
                        «Για το λόγο αυτό» συνεχίζει ο κ. Χαρακόπουλος «η
                        ΟΜ.Κ.Ε. ζητά οικονομική ενίσχυση ύψους 25 χιλιάδων ευρώ,
                        με σκοπό να καλύψει το κόστος που απαιτείται για την
                        εκπόνηση της μετάφρασης και της ηλεκτρονικής
                        επεξεργασίας του Κ.Ο.Κ., όπως επίσης, και της διανομής
                        του σε όλους τους κωφούς και βαρήκοους».
                      </li>
                      <br></br>
                      <li>
                        Και η ερώτηση καταλήγει ότι «επειδή, όλοι οι πολίτες
                        πρέπει να έχουν ίσες ευκαιρίες και δικαιώματα, θεωρούμε
                        εύλογο το αίτημα για την απόδοση του Κ.Ο.Κ. στη
                        νοηματική γλώσσα, καθώς θα προσφέρει τη δυνατότητα
                        ευκολότερης πρόσβασης σε αυτόν».
                      </li>
                    </ul>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
          </div>
          {/*  End .row */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
