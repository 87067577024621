import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                    παραμονής
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία διπλώματος
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    4 φωτογραφίες τύπου διαβατηρίου (εκ των οποίων οι 2 είναι
                    για τους γιατρούς )
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    ΑΦΜ
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Απολυτήριο λυκείου επικυρωμένο
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Καθαρό ποινικό μητρώο
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΑΠΑΙΤΟΥΜΕΝΕΣ ΔΗΛΩΣΕΙΣ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Χορήγηση άδειας ταξί:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "2704287deefa9a2028b918555fd42dd2c8"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 20.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Ανανέωση άδειας ταξί:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "d5e4b3693918f71fe65a7f945d65c41a1"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 21.pdf
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
