import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();
  // const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Δεκ 3, 2015
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Η εκπαίδευση γίνεται με τα δικά μας σύγχρονα και ειδικά
                      διαμορφωμένα οχήματα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Η εκπαίδευση των υποψηφίων οδηγών – γίνεται με τα δικά μας
                      σύγχρονα και ειδικά διαμορφωμένα οχήματα όλων των
                      κατηγοριών: μοτοσικλέτες, αυτοκίνητα, λεωφορεία, φορτηγά
                      και νταλίκες.
                    </p>
                    <a
                      href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa">
                      Νοε 22, 2013
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa">
                      Να εκδοθεί ο Κ.Ο.Κ. στην Ελληνική Νοηματική Γλώσσα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Να αποδοθεί στην ελληνική νοηματική γλώσσα ο Κώδικας
                      Οδικής Κυκλοφορίας (Κ.Ο.Κ.) και να διανεμηθεί σε κωφούς
                      και βαρήκοους ζητά ο βουλευτής Λαρίσης της Νέας
                      Δημοκρατίας κ. Μάξιμος Χαρακόπουλος, με ερώτηση που
                      απευθύνει στον υπουργό Οικονομίας, Υποδομών, Ναυτιλίας και
                      Τουρισμού κ. Γιώργο Σταθάκη. Όπως αναφέρει...
                    </p>
                    <a
                      href="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
            <div className="col-sm-6 col-md-4">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">
                    <a href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas">
                      Νοε 21, 2013
                    </a>
                  </div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas">
                      Οι εκπαιδευτές μας είναι αριστούχοι της σχολής μας
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Οι εκπαιδευτές μας είναι υπεύθυνοι δάσκαλοι, αριστούχοι
                      της σχολής μας με εμπειρία σε όλες τις εξεταστικές
                      υποψηφίων εκπαιδευτών.
                    </p>
                    <a
                      href="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas"
                      className="read-more"
                    >
                      ΠΕΡΙΣΣΟΤΕΡΑ
                    </a>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
          </div>
          {/*  End .row */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
