import React from "react";
import Scroll from "../widgets/scroll";
import Footer from "../widgets/footer";
import Header from "../widgets/header";
import Mobile from "../widgets/mobile";

const ExportDefault = (props) => {
  return (
    <React.Fragment>
      <div className="page-wrapper">
        <Header {...props} />
        <Footer {...props} />
      </div>
      <Scroll {...props} />
      <Mobile {...props} />
    </React.Fragment>
  );
};

export default ExportDefault;
