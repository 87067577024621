import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="mb-3">
                <ul>
                  <li>
                    <i
                      className="icon-check mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Μετατροπή από επαγγελματικό σε ερασιτεχνικό δίπλωμα
                  </li>
                  <li>
                    <i
                      className="icon-check mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αντικατάσταση παλιού διπλώματος σε καινούργιο
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                    παραμονής
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία διπλώματος
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    2 φωτογραφίες τύπου διαβατηρίου
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΑΠΑΙΤΟΥΜΕΝΕΣ ΔΗΛΩΣΕΙΣ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "229b4fb10ed645b6112d74bfac99f34rwef"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      ΑΙΤΗΣΗ ΕΚΤΥΠΩΣΗΣ.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αντικατάσταση:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "59ec21e8e5bcc4877b6d0e6c162c9d93"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 12.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Κλοπή ή απώλεια:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "5e4b3693918f71erfe65a7f945d65c41a1"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 14.pdf
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
