import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const urlHelper = component.getHelpers("url");

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-lg-12">
              <div className="products mb-3">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="product product-7 text-center">
                      <figure className="product-media">
                        <a
                          href="https://www.yme.gr/?tid=890"
                          style={{
                            height: "250px",
                            backgroundImage: `url(${urlHelper.createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              "gallery",
                              "default",
                              "vehicle-1"
                            )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></a>
                      </figure>
                      {/* End .product-media */}
                      <div className="product-body">
                        <h3 className="product-title">
                          <a href="https://www.yme.gr/?tid=890">ΜΗΧΑΝΗ</a>
                        </h3>
                      </div>
                      {/* End .product-body */}
                    </div>
                    {/* End .product */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="product product-7 text-center">
                      <figure className="product-media">
                        <a
                          href="https://www.yme.gr/?tid=890"
                          style={{
                            height: "250px",
                            backgroundImage: `url(${urlHelper.createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              "gallery",
                              "default",
                              "vehicle-2"
                            )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></a>
                      </figure>
                      {/* End .product-media */}
                      <div className="product-body">
                        <h3 className="product-title">
                          <a href="https://www.yme.gr/?tid=890">ΑΥΤΟΚΙΝΗΤΟ</a>
                        </h3>
                      </div>
                      {/* End .product-body */}
                    </div>
                    {/* End .product */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="product product-7 text-center">
                      <figure className="product-media">
                        <a
                          href="https://www.yme.gr/?tid=890"
                          style={{
                            height: "250px",
                            backgroundImage: `url(${urlHelper.createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              "gallery",
                              "default",
                              "vehicle-3"
                            )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></a>
                      </figure>
                      {/* End .product-media */}
                      <div className="product-body">
                        <h3 className="product-title">
                          <a href="https://www.yme.gr/?tid=890">ΛΕΩΦΟΡΕΙΟ</a>
                        </h3>
                      </div>
                      {/* End .product-body */}
                    </div>
                    {/* End .product */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="product product-7 text-center">
                      <figure className="product-media">
                        <a
                          href="https://www.yme.gr/?tid=890"
                          style={{
                            height: "250px",
                            backgroundImage: `url(${urlHelper.createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              "gallery",
                              "default",
                              "vehicle-4"
                            )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></a>
                      </figure>
                      {/* End .product-media */}
                      <div className="product-body">
                        <h3 className="product-title">
                          <a href="https://www.yme.gr/?tid=890">ΦΟΡΤΗΓΟ</a>
                        </h3>
                      </div>
                      {/* End .product-body */}
                    </div>
                    {/* End .product */}
                  </div>
                </div>
                {/* End .row */}
              </div>
              {/* End .products */}
            </div>
            {/* End .col-lg-9 */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
