import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();
  // const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-sm-8">
              <article className="entry entry-grid">
                <div className="entry-body text-center">
                  <div className="entry-meta">Δεκ 3, 2015</div>
                  {/*  End .entry-meta */}
                  <h2 className="entry-title">
                    <a href="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata">
                      Η εκπαίδευση γίνεται με τα δικά μας σύγχρονα και ειδικά
                      διαμορφωμένα οχήματα
                    </a>
                  </h2>
                  {/*  End .entry-title */}
                  <div className="entry-content">
                    <p>
                      Η εκπαίδευση των υποψηφίων οδηγών – γίνεται με τα δικά μας
                      σύγχρονα και ειδικά διαμορφωμένα οχήματα όλων των
                      κατηγοριών: μοτοσικλέτες, αυτοκίνητα, λεωφορεία, φορτηγά
                      και νταλίκες.
                    </p>
                    <iframe
                      style={{ maxWidth: "560px" }}
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/GO0o2_wRprM"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <br></br>
                    <iframe
                      style={{ maxWidth: "560px" }}
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/g0DEMyyKd0E"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {/*  End .entry-content */}
                </div>
                {/*  End .entry-body */}
              </article>
              {/*  End .entry */}
            </div>
            {/*  End .col-md-4 */}
          </div>
          {/*  End .row */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
