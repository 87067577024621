import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="widget widget-cats mb-3">
                <h3 className="widget-title">
                  <b>ΚΑΤΗΓΟΡΙΕΣ</b>
                </h3>
                <ul>
                  <li style={{ maxWidth: "350px" }}>
                    <a href="#">
                      Β’ Κατηγορία<span>18 ετών και άνω</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΠΡΟΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία αστυνομικής ταυτότητας ή διαβατηρίου και άδειας
                    παραμονής
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φωτοτυπία διπλώματος (εάν υπάρχει κάποια άλλη κατηγορία)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    4 φωτογραφίες τύπου διαβατηρίου (εκ των οποίων οι 2 είναι
                    για τους γιατρούς )
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    ΑΦΜ
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>ΑΠΑΙΤΟΥΜΕΝΕΣ ΔΗΛΩΣΕΙΣ</b>
                </h3>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "229b4fb10ed645b6112d74bfac99f34rwef"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      ΑΙΤΗΣΗ ΕΚΤΥΠΩΣΗΣ.pdf
                    </a>
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αν δεν έχετε άλλο δίπλωμα:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "9a8226f3af706dbf0fafuyjuhy8b527543dbe8"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 01.pdf
                    </a>{" "}
                    (αρχική χορήγηση)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αν έχετε δίπλωμα άλλης κατηγορίας:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "9a8226f3af706dbf0faf8b52ref7543dbe8"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 02.pdf
                    </a>{" "}
                    (επέκταση)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αν είστε 17 χρονών:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "9a8226f3af706dbf0faf8b527543dbe8"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 01 για 17άρηδες.pdf
                    </a>{" "}
                    (υπογραφή κηδεμόνα)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Για επαναχορήγηση διπλώματος λόγω ΣΕΣΟ:{" "}
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "78982dd2256852606dd4834b76bdaf026a"
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      M-TAO 33.pdf
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
